/*eslint-disable*/
import React from "react";
import Router from "next/router";
// @material-ui/core components
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";

import errorPageStyle from "styles/jss/nextjs-material-kit-pro/pages/errorPageStyles.js";

const useStyles = makeStyles(errorPageStyle);

export default function ErrorPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.pageHeader}>
        <div className={classes.contentCenter}>
          <img
            src={"/img/styler_logo_secondary.svg"}
            alt="Styler Logo"
            style={{ height: 60, marginBottom: '0.5em' }}
          />
          <h1 className={classes.title}>404</h1>
          <h2 className={classes.subTitle}>Page not found</h2>
          <h4 className={classes.description} style={{ marginBottom: '1em' }}>
            Oops! Looks like you got lost.
          </h4>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => Router.push("/signup")}
          >
            Go to sign-up
          </Button>
        </div>
      </div>
    </div>
  );
}
